<template>
  <div class="w-100">
    <v-text-field
      class="rounded-clg"
      :elevation="0"
      solo
      outlined
      label="Buscar visitante"
      append-icon="mdi-magnify"
      :dense="true"
      :hide-details="true"
      @input="search"
      v-model="term"
    ></v-text-field>
  </div>
</template>

<style scoped>
.w-100 {
  width: 100% !important;
}
</style>

<script>
export default {
  name: "SearchVisitors",
  data() {
    return {
      term: null,
    };
  },
  methods: {
    search() {
      let data;

      if (this.term?.length)
        data = this.term
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

      this.$emit("search", data);
    },
  },
};
</script>
