<template>
  <div>
    <div class="pa-3 d-flex flex-column">
      <div class="d-flex justify-center">
        <div class="col-12 col-md-5 col-lg-3">
          <h2 v-text="internalName"></h2>
          <span v-if="user" v-text="user.name"></span>
        </div>
      </div>

      <div class="d-flex justify-center">
        <div class="col-12 col-md-5 col-lg-3">
          <search-visitors @search="setTermSearch"></search-visitors>
        </div>
      </div>

      <div class="d-flex justify-center">
        <div class="col-12 col-md-5 col-lg-3">
          <item-list-visitors
            v-for="(item, i) in visitors"
            :key="i"
            :visitor="item"
          ></item-list-visitors>
        </div>
      </div>
    </div>
    <div class="pa-3 action-buttons">
      <div class="d-flex justify-center">
        <div class="col-12 col-md-5 col-lg-3">
          <div class="d-flex flex-column">
            <v-btn
              class="rounded-lg white--text mb-2 text-capitalize"
              color="primary"
              @click="syncVisitors"
            >
              <v-icon class="mr-2">mdi-autorenew</v-icon>
              Sincronizar
            </v-btn>
            <load-qr @add-temp-visitor="addTempVisitor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-buttons {
  position: fixed;
  bottom: 56px;
  width: 100%;
  background: white;
}
</style>

<script>
import sessionMixin from "@/mixins/sessionMixin";
import ItemListVisitors from "./components/ItemListVisitors";
import SearchVisitors from "./components/SearchVisitors";
import LoadQr from "./components/LoadQr.vue";
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  components: {
    ItemListVisitors,
    SearchVisitors,
    LoadQr,
  },
  name: "Visitors",
  data() {
    return {
      iSearch: "",
    };
  },
  methods: {
    setTermSearch(value) {
      this.iSearch = value;
    },
    addTempVisitor(data) {
      this.$store.commit("visitors/setTempVisitor", data);
      this.$router.push({ name: "visitor" });
    },
    async syncVisitors() {
      let response = await this.$store.dispatch("visitors/syncVisitors");

      if (response.status) {
        this.showSuccess("Visitantes sincronizados");
      } else {
        if (!this.$store.getters["offline/isOnline"]) {
          this.showWarning("Sin conexión a internet");
        }
      }
    },
  },
  computed: {
    visitors: {
      get() {
        return this.$store.getters["visitors/visitorsUser"](this.iSearch);
      },
      set(value) {
        this.$store.commit("visitors/setVisitors", value);
      },
    },
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    user() {
      return this.$store.getters["auth/userInfo"]?.user;
    },
    sync() {
      return this.$store.getters["visitors/sync"];
    },
    isOnline() {
      return this.$store.getters["offline/isOnline"];
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "EXCLE")[0]
          ?.internalName ?? "Registros Visitantes"
      );
    },
  },
  watch: {
    sync(value) {
      if (value) {
        this.showLoader();
      } else {
        this.hideLoader();
      }
    },
    isOnline(value) {
      if (value) {
        this.$store.dispatch("visitors/syncVisitors");
      }
    },
  },
  created() {
    let userId = this.$store.getters["auth/userInfo"]?.user?.id;
    this.$store.commit("visitors/setUserKey", userId);
    this.$store.dispatch("visitors/syncVisitors");
  },
  mixins: [sessionMixin, loaderMixin, notificationMixin],
};
</script>
